<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学术系统</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 课程信息筛选 -->
    <el-card style="margin-top: 15px" v-if="this.queryForm.matchType !== '17'">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">条件筛选</span>
        </el-col>
      </el-row>
      <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="课程名称" prop="productName">
              <el-input
                v-model="queryForm.productName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- <el-form-item label="课程类型" prop="courseType">
              <el-cascader
                v-model="queryForm.selectId"
                :options="courseOptions"
                :props="courseParams"
                style="width: 90%"
                clearable>
              </el-cascader>
            </el-form-item> -->
            <el-form-item label="课程分类" prop="category">
              <el-select
                v-model="queryForm.category"
                style="width: 100%"
                ref="categoryRef"
                @change="categoryChange"
                clearable
              >
                <el-option
                  v-for="(item, index) in dict_category"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
                  :index="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="课程主题" prop="subCategory">
              <el-select
                v-model="queryForm.subCategory"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_subCategory"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="课程类型" prop="subTypeCategory">
              <el-select
                v-model="queryForm.subTypeCategory"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_courseType"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="6">
            <el-form-item label="课程Id" prop="courseId">
              <el-input
                v-model="queryForm.courseId"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="课程类型" prop="courseType">
              <el-select v-model="queryForm.courseType">
                <el-option
                  v-for="item in dict_courseType"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="课程分类" prop="courseClass">
              <el-select v-model="queryForm.courseClass">
                <el-option
                  v-for="item in dict_courseClass"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="课程价格" prop="coursePrice">
              <el-select v-model="queryForm.coursePrice">
                <el-option
                  v-for="item in dict_coursePrice"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getCourseDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 课程信息列表信息列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="15">
          <span class="title-class title_class">课程信息列表</span>
        </el-col>
        <el-col :span="9">
          <div class="div_algin_right">
            <el-button type="primary" @click="addCourseInfoClicked()"
              >新增课程+</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <!-- <el-table-column label="课程id" prop="productCode"></el-table-column> -->
        <el-table-column
          label="课程分类"
          prop="category"
          :formatter="categoryFormatter"
        ></el-table-column>
        <el-table-column label="课程名称" prop="productName"></el-table-column>
        <el-table-column label="价格" prop="price">
          <template slot-scope="scope">
            <span style="color: gray" v-if="scope.row.price === '0.00'">
              免费</span
            >
            <span style="color: red" v-else> ￥{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column label="有效天数" prop="days">
          <template slot-scope="scope">
            <span>{{
              scope.row.days
                ? scope.row.days == "9999"
                  ? "永久有效"
                  : scope.row.days
                : "本赛季有效"
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="课程类型"
          prop="subTypeCategory"
          :formatter="subTypeCategoryFormatter"
        ></el-table-column> -->
        <!-- <el-table-column
          label="类型"
          prop="isPackage"
          :formatter="isPackageFormatter"
        ></el-table-column> -->
        <el-table-column
          label="有效类型"
          prop="handType"
          :formatter="handTypeFormatter"
        ></el-table-column>
        <el-table-column label="到期时间" prop="expiryDate"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="210px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="editDistrictMessage(scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="scope.row.productStatus === 1"
              type="text"
              icon="el-icon-document-remove"
              size="small"
              style="color: #E6A23C;"
              @click="takeOffDialog(scope.row)"
              >下架</el-button
            >
            <el-button
              v-if="
                scope.row.productStatus === 2 || scope.row.productStatus === 0
              "
              type="text"
              icon="el-icon-document-add"
              size="small"
              style="color: #67C23A;"
              @click="takeupDialog(scope.row)"
              >上架</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              style="color: #f56c6c;"
              @click="deleteDialog(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { getTbProductListInfoPage, deleteProductInfo, updateTakeOffTheShelf, secondaryLinkageCourseQuery } from '@/http/api'
export default {
  data () {
    return {
      courseParams: {
        label: 'typeName',
        value: 'id',
        children: 'childrenCourseType',
        multiple: true
      },
      dict_category: [],
      dict_subCategory: [],
      dataList: [],
      total: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        subCategory: null,
        activityType: this.$chnEngStatusCode.activityCourseType
      },
      dict_isPackage: this.$userInfo.dict_isPackage(),
      dict_courseType: this.$userInfo.dict_courseType(),
      dict_coursePrice: this.$userInfo.dict_coursePrice(),
      dict_courseClass: this.$userInfo.dict_courseClass(),
      dict_handType: this.$userInfo.getDataList(this, 'p_hand_type').then(value => { this.dict_handType = value })
    }
  },
  created () {
    this.getSecondaryLinkageCourseQuery()
    this.getCourseDataList()
  },
  activated: function () {
    this.getCourseDataList()// 数据加载的方法
  },
  methods: {
    // 获取课程分类二级菜单
    getSecondaryLinkageCourseQuery () {
      secondaryLinkageCourseQuery().then((res) => {
        this.dict_category = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 课程分类选择
    categoryChange (value) {
      this.dict_subCategory = this.dict_category[this.$refs.categoryRef.hoverIndex].childrenCourseType
      this.queryForm.subCategory = null
      console.log('dict_subCategory', this.dict_subCategory)
    },
    // 获取课程数据
    getCourseDataList (type) {
      console.log('queryForm', this.queryForm)
      if (type && type === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 20
      }
      getTbProductListInfoPage(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 20
      this.getCourseDataList('search')
    },
    // 新增课程点击事件
    addCourseInfoClicked () {
      this.$router.push(
        {
          path: '/academic_Management/academic_course_management/academic_course_detail'
        }
      )
    },
    // 编辑按钮点击事件
    editDistrictMessage (rowData) {
      this.$router.push(
        {
          path: '/academic_Management/academic_course_management/academic_course_detail',
          query: {
            courseId: rowData.id
          }
        }
      )
    },
    // 下架
    takeOffDialog (rowData) {
      this.$confirm('是否将该课程下架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确认下架
        updateTakeOffTheShelf({ id: rowData.id, productStatus: 2 }).then((res) => {
          this.$message.success('课程下架成功！')
          this.getCourseDataList()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消下架'
        })
      })
    },
    // 上架
    takeupDialog (rowData) {
      this.$confirm('是否将该课程上架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确认上架
        updateTakeOffTheShelf({ id: rowData.id, productStatus: 1 }).then((res) => {
          this.$message.success('课程上架成功！')
          this.getCourseDataList()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消上架'
        })
      })
    },
    // 删除
    deleteDialog (rowData) {
      this.$confirm('是否将该课程信息删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        // 确认删除
        deleteProductInfo({ id: rowData.id, productStatus: rowData.productStatus }).then((res) => {
          this.$message.success('删除成功！')
          this.getCourseDataList()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }).catch(() => {
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getCourseDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getCourseDataList()
    },
    typeFormatter (data) {
      var temp = '-'
      for (const key in this.dict_courseType) {
        if (Object.hasOwnProperty.call(this.dict_courseType, key)) {
          const element = this.dict_courseType[key]
          if (element.dictValue === data.courseCtype) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    categoryFormatter (data) {
      var temp = '-'
      for (const key in this.dict_category) {
        if (Object.hasOwnProperty.call(this.dict_category, key)) {
          const element = this.dict_category[key]
          if (element.id === data.category) {
            temp = element.typeName
          }
        }
      }
      return temp
    },
    courseClassFormatter (data) {
      var temp = '-'
      for (const key in this.dict_courseClass) {
        if (Object.hasOwnProperty.call(this.dict_courseClass, key)) {
          const element = this.dict_courseClass[key]
          if (element.dictValue === data.courseClass) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    isPackageFormatter (data) {
      var temp = '-'
      for (const key in this.dict_isPackage) {
        if (Object.hasOwnProperty.call(this.dict_isPackage, key)) {
          const element = this.dict_isPackage[key]
          if (element.dictValue === data.isPackage) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    handTypeFormatter (data) {
      var temp = '-'
      for (const key in this.dict_handType) {
        if (Object.hasOwnProperty.call(this.dict_handType, key)) {
          const element = this.dict_handType[key]
          if (element.dictValue === data.handType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    subTypeCategoryFormatter (data) {
      var temp = '-'
      for (const key in this.dict_courseType) {
        if (Object.hasOwnProperty.call(this.dict_courseType, key)) {
          const element = this.dict_courseType[key]
          if (element.dictValue === data.subTypeCategory) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
  text-align: right;
}
</style>
